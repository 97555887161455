import $ from "jquery";
import "../styles/css/couleurs.css"

import {manageScrollBar,removeLocal, toggleDivOnClick} from "../outils/OutilsDivers";
import {confirm_photo,managePicture, managePhotoCollection} from "../outils/OutilsPhotos";
import {confirm_form} from "../outils/OutilsConfirm";



$(document).ready(function () {

   //on va chercher le type de page
   let page=$('#page').attr('data-type');


   // // //activation du hide/show sur un bouton avec la classe js-arrow
   // toggleDivOnClick()

   // activation de la gestion de la picture
   if(page === 'auteur_edit' || page==='peintre_edit' || page==='radio_edit' || page==='tag_edit' || page==='lien_edit' || page==='user_edit' || page==='magasin_edit' || page==='movie_edit' ){
     managePicture()
     toggleDivOnClick()
   }
   if(page==='peintre_edit' || page==='album_edit'){
      managePhotoCollection()
   }


   //activation du Modal
   if(page === 'photos_index' || page==='auteur_edit' || page==='radio_edit' || page==='tag_edit' || page==='lien_edit' || page==='user_edit' ){
     confirm_form()
   }else{
      if(page === 'peintre_edit'){
         confirm_photo()
      }else{
         confirm_form()
      }

   }

   if(page === 'jingles_index'){
     manageScrollBar()
   }

   if(page === 'peintres_index'){
    removeLocal('captions')
   }


})




